import React, {useEffect} from 'react';
import cx from 'classnames';
import s from './CartApp.scss';
import {CartBody} from './partials/CartBody';
import {EmptyState} from '../Components/EmptyState/EmptyState';
import {useControllerProps} from '../../../../domain/controllers/ControllerContext';
import {withResizeObserver} from './HOC/withResizeObserver';
import {withSentryErrorBoundary} from '@wix/native-components-infra';
import {CART_DSN} from '@wix/wixstores-client-storefront-sdk';
import './vars.scss';
import {ErrorToast} from '../../../../common/components/ErrorToast/ErrorToast';
import {useEnvironment} from '@wix/yoshi-flow-editor';
import {ToastPlacement} from 'wix-ui-tpa';
import {SlotPlaceHolder} from '../Components/SlotPlaceHolder/SlotPlaceHolder';
import {SlotsMap} from '../../../../common/constants';

export enum CartAppDataHook {
  root = 'CartAppDataHook.root',
}

const CartApp = () => {
  const {isResponsive} = useControllerProps();
  const {shouldRenderEmptyState, AddSlotToCartPage} = useControllerProps().cartStore;
  const {handlePaymentMethodError} = useControllerProps().cashierExpressStore;
  const viewerVersion = isResponsive ? 'viewer_x' : 'viewer_classic';
  const classNames = cx(s.main, s[viewerVersion], 'cart');
  const {isMobile} = useEnvironment();

  useEffect(() => {
    handlePaymentMethodError();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className={s.container}>
      <main data-hook={CartAppDataHook.root} className={classNames} data-wix-cart>
        <div className={!isMobile ? cx(s.toastContainer) : null}>
          <ErrorToast placement={isMobile ? ToastPlacement.bottomFullWidth : ToastPlacement.inline} />
        </div>
        {shouldRenderEmptyState ? <EmptyState /> : <CartBody />}
        {AddSlotToCartPage && <SlotPlaceHolder slotId={SlotsMap.mainBottom} />}
      </main>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default withSentryErrorBoundary(withResizeObserver(CartApp), {
  dsn: CART_DSN,
  config: {environment: 'Native Component'},
});
